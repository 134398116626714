import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Thank You Winsupply!';
  phone = {
    display: '937.422.5599',
    href: 'tel:19374225599'
  };
  email = 'brianstephens3@gmail.com';
  linkedin = {
    url: 'https://www.linkedin.com/in/stephensdesigns/',
    display: 'LinkedIn'
  };
}
